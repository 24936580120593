import React from 'react'
import UnSupportAlert from '../components/common/UnSupportAlert';

const Maintenance = () => {
    return (
        // <div>
        //     <section className="flex flex-wrap lg:flex-nowrap justigy-center h-screen relative">
        //         <div>
        //             This site is under maintenance as part of our Testnet Phase.
        //         </div>
        //     </section>
        // </div>
        <UnSupportAlert type={'maintenance'}/>
    )
}

export default Maintenance;
